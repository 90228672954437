import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import elevator1 from "../images/elevator-full.jpg";

function FurnitureDelivery() {
  return (
    <>
      <NavBar
        title="Furniture Delivery | Point Blank Moving"
        description="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best furniture deliver service in Kansas City, Missouri."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${elevator1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Furniture Delivery Service
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h2
              style={{
                fontSize: "30px",
                marginTop: 20,
                marginBottom: 10,
                padding: 10,
                textAlign: "center",
              }}
            >
              Need a Furniture Delivery Service in Kansas City?{" "}
            </h2>
            <div>
              As a full-service Kansas City moving company, Point Blank Moving
              specializes in safe and stress-free furniture delivery. Whether
              you are a homeowner or business owner waiting for a new piece of
              furniture to be delivered, or needing your furniture moved from a
              current location to your new location; we will receive, uncrate,
              inspect and transfer your new product to your home or office. Our
              team of trained furniture movers are experienced and reliable. We
              will receive your shipment and carefully ensure your items arrive
              on time.
            </div>
          </div>
          <div className="content--loc--generic">
            <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Point Blank Furniture Moving Services
            </h3>
            <div>
              If you live in a small apartment or home, it can be difficult to
              receive shipments. Point Blank Moving will receive your shipment
              for you. With 90% of our customers referring friends or family
              members to us, we have earned the trust and loyalty of our
              customers over the past 5+ years serving the Kansas City area. We
              are honored to help our customers move their most valued
              possessions. Many items like pool tables, fine art, antiques, and
              other unique pieces require extra care and attention when
              relocating. We also specialize in piano moving and large furniture
              moving. Point Blank Moving makes no exception when it comes to
              delicately handling your priceless possessions, no matter what
              they are.
            </div>
          </div>
          <div className="content--loc--generic">
            <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Specializing in Moving High-Value Items
            </h3>
            <div>
              Our full-time professional movers specialize in moving high-value
              pieces, antique furniture, pianos, and special equipment. Our team
              works with you to efficiently schedule your needs for specialty
              moving in a way that meets your time and cargo-sensitive
              requirements.
              <br />
              <br />
              As a full-service moving company, we are able to provide a range
              of moving and storage options to meet your needs and requirements.
              We are experienced in providing our customers with:
              <ul style={{ marginTop: 10, marginLeft: 30 }}>
                <li>
                  Safe, timely, and cost-effective transportation for a range of
                  specialty items such as computer systems, scientific and
                  medical equipment, fine art, antique furniture, motorcycles,
                  and pianos
                </li>
                <li>
                  Packing and unpacking to alleviate stress, reduce time spent
                  on preparing, and ensure belongings are safely packed and
                  unloaded
                </li>
              </ul>
              <br />
              Our professional specialty movers and drivers have helped Point
              Blank Moving gain the trust and loyalty of our customers for their
              Kansas City specialty moving needs. Contact us today to request a
              quote for your moving needs.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FurnitureDelivery;
